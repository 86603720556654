import { Container, makeStyles, Typography } from "@material-ui/core";
import Carousel from "./Carousel";
import React from "react";


const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: "url(./landingPage.png)",
  },
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
}));



function Banner() {
  const classes = useStyles();
  // const [images, setImages] = useState([]);
  // useEffect(() => {
  //   const unsub = async () => {
  //     const { data } = await axios(
  //       'https://yed.moviefreex24.com/jwlist?host=' + getRandomInt(1, 10) + '&page=' + getRandomInt(1, 1000)
  //     );
      
  //     setImages(data?.AV_FW_ITEMS);
  //   };
  //   return unsub();
  // }, []);
  //console.log(images)
  return (
    <div className={classes.banner}>
    
      <Container className={classes.bannerContent}>
        
        <div className={classes.tagline}>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Kanit",
            }}
          >
            AVFREEX24 BomB
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "darkgrey",
              textTransform: "capitalize",
              fontFamily: "Kanit",
            }}
          >
            ดูหนังJAV คลิปหลุดฟรีไม่มีโฆษณา และอัพเดทราคาเหรียญbombทุกวินาที
          </Typography>
        </div>
        
        <Carousel />
        
      </Container>
    </div>
  );
}

export default Banner;

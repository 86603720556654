import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { useEffect, useState } from "react";
// import ReactPlayer from 'react-player';
// import heroVideo from './HeroVideo.mp4';
import ReactJWPlayer from 'react-jw-player';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '80vh',
    position: 'relative',
    '& video': {
      objectFit: 'cover',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
}));

const Hero = () => {
  const classes = useStyles();
  function getRandomInt(min, max) {
    min = Math.ceil(1); max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }
  
  const [images, setImages] = useState([]);
  useEffect(() => {
    const unsub = async () => {
      const { data } = await axios(
        'https://yed.moviefreex24.com/jwlist?host=' + getRandomInt(1, 9) + '&page=' + getRandomInt(1, 1000)
      );
      
      setImages(data?.AV_FW_ITEMS);
    };
    return unsub();
  }, []);

  return (
    <section className={classes.root}>
      <ReactJWPlayer
        
        style={{ height: "100%", width: "100%" }}
        playerId={"TAITbudl"}
        playerScript={"https://content.jwplatform.com/libraries/j9BLvpMc.js"}
        playlist={images}
        isAutoPlay={true}
        generatePrerollUrl={images.file}
        licenseKey={"uoW6qHjBL3KNudxKVnwa3rt5LlTakbko9e6aQ6VUyKQ="}
      />
       {/*<div className={classes.overlay}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color="#fff"
        >
          <Typography variant="h3" component="h1" className={classes.title}>
            Title Goes Here
          </Typography>
          <Button color="primary" variant="contained">
            Click Me
          </Button>
        </Box>
      </div> */}
    </section>
  );
};

export default Hero;
const firebaseConfig = {
  apiKey: "AIzaSyD47QWXzBqPPOX7hsE2vAFszm8aTY6f0BI",
  authDomain: "ru-4569f.firebaseapp.com",
  databaseURL: "https://ru-4569f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ru-4569f",
  storageBucket: "ru-4569f.appspot.com",
  messagingSenderId: "438373185829",
  appId: "1:438373185829:web:ab46ee22016356fd91c2cc",
  measurementId: "G-E41K8N0TZG"
};

export default firebaseConfig;
